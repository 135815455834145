
import React, { useState, useEffect } from 'react';

import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Popup from '../Component/Popup'
import HomeSlider from '../Component/HomeSlider'
import Birthday from '../Component/Birthday'
import TopperX from '../Component/TopperX'
import News from '../Component/News'
import Calender from '../Component/Calender'
import Campuscare from '../Component/Campuscare'
import OnlineRegistration from '../Component/OnlineRegistration'
import Activities from '../Component/Activities'
import Gallery from '../Component/Gallery'
import Message from '../Component/Message'
import ManagerMessage from '../Component/ManagerMessage'
import NoticeBoard from '../Component/NoticeBoard'
import Events from '../Component/Events'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';

const Home = () => {
  const [circulars, setCirculars] = useState([]);

  useEffect(() => {
    document.title = 'Home - St. Joseph’s High School, Dabwali';

    const fetchCirculars = async () => {
      const API_URL = 'https://webapi.entab.info';
      try {
        const response = await fetch(`${API_URL}/api/highlight/all/SJHSMD`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        // Filter for 'Notice' category
        const notices = data.filter(event => event.category === 'Notice');
        // Sort notices by date
        setCirculars(notices.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate)));
      } catch (error) {
        console.error('Error fetching circulars:', error);
      }
    };

    fetchCirculars();
  }, []);

  return (
    <>
      <Header />

      <div id="Registration-content">
        <div className="I-notice" id="circularsContainer">
          {circulars.length === 0 ? (
            <p></p>
          ) : (
            circulars.map((circular, index) => (
              <div key={index} className="blinkbg">
                {/* Conditionally render the attachment link */}
                {circular.attachments?.length > 0 && (
                  <a
                    href={`https://webapi.entab.info/api/image/${circular.attachments[0]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-paperclip"></i>
                  </a>
                )}

                {/* Using the circular's 'url' field dynamically */}
                <a href={circular.url} target="_blank" rel="noopener noreferrer">
                  {circular.title}
                </a>
              </div>
            ))
          )}
        </div>
      </div>


      <div className="bodybg">
        <Campuscare />
        <Popup />
        <OnlineRegistration />
        <div className="campuslogo headersocialicn">
          <Link to="https://instagram.com/st_joseph_dabwali?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank">
            <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/insta.png" className="img-fluid float-end campus" alt="SJHSMD" /></LazyLoad>
          </Link>
          <Link to="https://www.facebook.com/profile.php?id=100095356204619&mibextid=ZbWKwL" target="_blank">
            <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/fbicn.png" className="img-fluid float-end campus" alt="SJHSMD" /></LazyLoad>
          </Link>
        </div>
        <div className='slidersec'>
          <div className='slidersecfst padding-0 order-lg-1 order-md-2'>
            <div className="aboutnews">
              <News />
            </div>
          </div>
          <div className='slidersecscd padding-0 order-1'>
            <HomeSlider />
          </div>
        </div>

        <div className="aboutmidsec">
          <div className='button'>
            <Link to="/" target="_blank">
              <p> Admission</p>
            </Link>
          </div>
          <div className='button'>
            <Link to="https://entab.online/SJSMHR" target='_blank'>
              <p>Online payment</p>
            </Link>
          </div>
          <div className='button'>
            <Link to="/Pphelp">
              <p>portal(help)</p></Link>
          </div>
          <div className='phone'>
            <Link to="tel:+91-9729526844">
              <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/phone.gif" alt="St Joseph high school" /></LazyLoad>
            </Link>
          </div>
          <div className='button'>
            <Link to="https://entab.online/SJSMHR" target="_blank">
              <p>alumnae registration</p>
            </Link>
          </div>
          <div className='button'>
            <Link to="/">
              <p> booklist</p>
            </Link>
          </div>
          <div className='button'>
            <Link to="/" target="_blank">
              <p>Bus route</p>
            </Link>
          </div>
        </div>
        <div className="aboutsec">
          <div className="container-fluid fulwidth">
            <div className="row">

              <div className="col-lg-6">
                <div className="about-img">
                  <div className='circle abt_abs abs-img1'>
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/about1.jpg" alt="St Joseph high school" /></LazyLoad>
                  </div>
                  <div className='circle abt_abs abs-img2'>
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/about2.jpg" alt="St Joseph high school" /></LazyLoad>
                  </div>
                  <div className='center-img'>
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/aboutimg.png" className='abs-img3' alt="St Joseph high school" /></LazyLoad>
                  </div>
                  <div className='circle abt_abs abs-img4'>
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/about3.jpg" alt="St Joseph high school" /></LazyLoad>
                  </div>
                  <div className='circle abt_abs abs-img5'>
                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/about4.jpg" alt="St Joseph high school" /></LazyLoad>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 about-bg">
                <div class="about-news">
                  <div className='title'>
                    <h2>St. Joseph’s High School, <span>Mandi</span></h2>
                  </div>
                  <p>St. Joseph’s High School, Dabwali is run by the registered Society of Pilar, Punjab- Haryana, a branch of the Society of Pilar, Goa.The school is run by the religious minority in fulfillment of its constitution right under article 30 (1).This school is affiliated to the Council for the Indian School Certificate Examination, New Delhi. St. Joseph’s High School was opened in 1970 to cater to the pressing educational needs of Mandi Dabwali and the surroundings villages. The school aims at all round development of the child, moral along with the hope of drawing out the best in each, so as to enable its pupils to blossom into gems of persons and capable citizens of our country.
                    <br />
                    <br />The school caters to the students up to Std-X. The medium of instruction is English. Hindi and Punjabi are also taught as additional languages. Having gauged the usefulness of computers, smart classes, the school gives stress to training in Computer Science.An atmosphere of warmth and caring is provided by capable teachers to each child so that he/she blossoms to their full stature.</p>
                  <Link to="/About"><button className='button'>Read More</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event">
          <div className="container-fluid padded-sec">
            <div className="row">

              <div className='col-md-12 section-title'>
                <div class="maintitle">

                  <div><h1>FROM THE DESK OF </h1> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;message section  </p> </div>    </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-12">
                <Message />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-12">
                <ManagerMessage />
              </div>
            </div>
          </div>
        </div>
        <div className='calender'>
          <div className='container-fluid padded-sec'>
            <div className='row'>
              <div className='col-lg-4 calanderhead'>
                <div className='col-md-12 section-title'>
                  <div class="maintitle"><div><h1>Academic  </h1> <p>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Calendar  </p> </div><a href="/">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/btn.png" alt="SJMH" /> </LazyLoad>
                  </a><div class="clr"></div></div>

                </div>
                <Calender />
              </div>
              <div className='col-lg-8'>
                <div className="actibox">
                  <div className='col-md-12 section-title'>
                    <div class="maintitle"><div> <h1>School  </h1> <p> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Achivement  </p> </div><a href="/Activites">
                      <LazyLoad><img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/btn.png" alt="SJMH" /> </LazyLoad>
                    </a><div class="clr"></div></div>

                  </div>
                  <Activities />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='schoolnotice'>
          <div className='container-fluid padded-sec'>
            <div className='row'>
              <div className='col-lg-4 schoolnoticehead'>

                <NoticeBoard />
              </div>
              <div className='col-lg-8'>
                <div className="actibox">
                  <div className='col-md-12 section-title'>
                    <div class="maintitle"><div><h1>upcoming event </h1> <p> section  </p> </div><a href="/Events">
                      <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/btn.png" alt="SJMH" /> </LazyLoad>
                    </a><div class="clr"></div></div>

                  </div>
                  <Events />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topper_bg padded-sec">
          <div className="container-fluid section-title">
            <div className="row">
              <div className="col-lg-6">
                <div className='topper-block chng_topper'>
                  <div className='col-md-12 section-title'>
                    <div class="maintitle">

                      <div> <div><h1>Toppers </h1> <p> section  </p> </div>
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/topbg.png" class="img-fluid" alt="SJHSM" /> </LazyLoad>
                      </div>  <Link to="/Toppers">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/btn.png" class="img-fluid" alt="SJHSM" /></LazyLoad>
                      </Link>   </div>

                  </div>
                  <TopperX />
                </div>
                {/*  <button className='button'><Link to="/Toppers">Read More</Link></button> */}
              </div>

              <div className="col-lg-6">
                <div className='topper-block'>
                  <div className='title'>
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/birbg.png" class="img-fluid birthday_icon_new" alt="SJHSM" /></LazyLoad>
                    {/* <div className='arrrow-btn'>
            <Link to="/">
            <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/btn.png" class="img-fluid" alt="SJHSM"/>
          </Link> 
        </div> */}
                  </div>
                  <Birthday />
                </div>
                {/* <button className='button'><Link to="/ToppersXII">Read More</Link></button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="homegallery padded-sec">
          <div className="container-fluid fulwidth">
            <div className="row">
              <div className='col-md-12 section-title'>
                <div class="maintitle"><div><h1>Activities </h1> <p> in Images <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/galerygl.png" class="img-fluid" alt="SJHSM" />  </p> </div> <Link to="/Gallery">
                  <LazyLoad>  <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/btn.png" class="img-fluid" alt="SJHSM" /></LazyLoad>
                </Link><div class="clr"></div>

                </div>

              </div>
              <div className="col-lg-12">

                <Gallery />
                {/*  <button className='button'><Link to="/Gallery">Read More</Link></button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home